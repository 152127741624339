import FormAVIF from './form@1x.avif';
import FormPNG from './form@1x.png';
import FormAVIF2x from './form@2x.avif';
import FormPNG2x from './form@2x.png';

export default function Picture() {
    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${FormAVIF.src} 1x, ${FormAVIF2x.src} 2x`}
            />
            <source srcSet={`${FormPNG.src} 1x, ${FormPNG2x.src} 2x`} />
            <img
                width={FormPNG.width}
                height={FormPNG.height}
                decoding="async"
                loading="lazy"
                alt=""
                src={FormPNG.src}
            />
        </picture>
    );
}
